import { extendTheme } from "@chakra-ui/react";
import Link from "./componentStyles/LinkStyle";
import Button from "./componentStyles/ButtonStyle";
import Drawer from "./componentStyles/DrawerStyle";
import MenuButton from "./componentStyles/MenuStyle";

export const campfireHostingTheme = extendTheme({
  components: {
    Button,
    Link,
    Drawer,
    MenuButton,
  },
});
