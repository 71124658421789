import styled from "styled-components";
import colors from "../../theme/colors";

export const MaxWidthDiv = styled.div<{ width: string }>`
  max-width: ${(props) => props.width};
`;
export const MinWidthDiv = styled.div<{ width: string }>`
  min-width: ${(props) => props.width};
`;
export const ConstWidthDiv = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`;
export const ConstHeightDiv = styled.div<{ height: string }>`
  height: ${(props) => props.height};
`;

export const HrLine = styled.hr`
  border: 1px dashed ${colors.gray[500]};
  margin: auto;
`;

export const Ribbon = styled.div`
  & {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
  }
  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;

    border-top-color: transparent;
    border-left-color: transparent;
  }
  &::before {
    top: 0;
    right: 0;
  }
  &::after {
    bottom: 0;
    left: 0;
  }
`;

export const RibbonText = styled.span`
  font-weight: 500;
  position: absolute;
  display: block;
  width: 225px;
  padding: 13px 0;
  background-color: #c53030;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  right: -25px;
  top: 10px;
  transform: rotate(-45deg);
  line-height: 14px;
  font-size: 16px;
`;

export const CircleDiv = styled.div<{
  color?: string;
}>`
  border-radius: 50%;
  background-color: ${(props) => props.color || "white"};
  display: inline-flex;
  width: auto;
`;

export const FilledCircle = styled.div<{ color: string; size: string }>`
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: inline-flex;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

export const FilledSquare = styled.div<{ color: string; size: string }>`
  border-radius: 20%;
  background-color: ${(props) => props.color};
  display: inline-flex;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;
