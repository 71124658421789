import { defineStyleConfig } from "@chakra-ui/react";

const Link = defineStyleConfig({
  baseStyle: {
    textColor: "blue.600",
    bold: "true",
  },
});

export default Link;
