export function countDifferences(obj1: any, obj2: any): number {
  let count = 0;

  // Helper function to recursively count differences
  const countDifferencesRecursive = (obj1: any, obj2: any) => {
    for (const key in obj1) {
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        countDifferencesRecursive(obj1[key], obj2[key]); // Recurse if both values are objects
      } else if (
        obj1[key] !== obj2[key] &&
        !valuesUndefinedAndNull(obj1[key], obj2[key])
      ) {
        count++; // Increment count if values are different
      }
    }
  };

  countDifferencesRecursive(obj1, obj2);
  return count;
}

export function removeKeyFromObject<T extends object>(key: keyof T, obj: T) {
  return Object.keys(obj).reduce((acc, currentKey) => {
    if (key !== currentKey) {
      acc[currentKey] = obj[currentKey as keyof T];
    }

    return acc;
  }, {} as Record<string, any>);
}

function valuesUndefinedAndNull(a: any, b: any) {
  return (a === undefined && b === null) || (a === null && b === undefined);
}
