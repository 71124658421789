const BASE_URL = "campfirehosting.com";

export const getAdminUrl = () => "/admin";

// Servers

export const getServersOverviewUrl = () => `${getAdminUrl()}/servers`;

export const getCreateServerUrl = () => `${getServersOverviewUrl()}/create`;

const getServerUrl = (serverId: string) =>
  `${getServersOverviewUrl()}/${serverId}`;

export const getServerGeneralUrl = (serverId: string) =>
  `${getServerUrl(serverId)}/general`;

export const getServerMonitoringUrl = (serverId: string) =>
  `${getServerUrl(serverId)}/monitoring`;

export const getDeleteServerUrl = (serverId: string) =>
  `${getServerGeneralUrl(serverId)}/delete`;

export const getServerFtpUrl = (serverId: string) =>
  `${getServerUrl(serverId)}/ftp`;

export const getServerPropertiesUrl = (serverId: string) =>
  `${getServerUrl(serverId)}/properties`;

export const getServerAdvancedPropertiesUrl = (serverId: string) =>
  `${getServerUrl(serverId)}/advanced`;

export const getEditServerUrl = (serverId: string) =>
  `${getServerUrl(serverId)}/edit`;

// Credits

export const getCreditsUrl = () => `${getAdminUrl()}/credits`;

// Billing

export const getBillingUrl = () => `${getAdminUrl()}/billing`;

// Account

export const getAccountUrl = () => `${getAdminUrl()}/account`;

export const getSupportUrl = () => `${getAdminUrl()}/support`;

// Referral code

const referralCodeQueryParamName = "referral_code";

export const getReferralCodeQueryParam = (referralCode: string) =>
  `${referralCodeQueryParamName}=${referralCode}`;

export const getReferralCodeLink = (referralCode: string) =>
  `${BASE_URL}?${getReferralCodeQueryParam(referralCode)}`;

export const getReferralCodeFromUrl = () =>
  new URLSearchParams(window.location.search).get(referralCodeQueryParamName) ||
  undefined;
