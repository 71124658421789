enum RemoteEnvironment {
  QA,
  Prod,
  Localhost,
}

const TARGET_ENVIRONMENT_IF_LOCAL: RemoteEnvironment =
  RemoteEnvironment.Localhost;

export enum Environment {
  QA,
  Prod,
  Local,
  Unknown,
}

export function getEnvironment() {
  const { hostname } = window.location;

  if (hostname.startsWith("qa.campfirehosting.com")) {
    return Environment.QA;
  }
  if (hostname.startsWith("campfirehosting.com")) {
    return Environment.Prod;
  }
  if (hostname.startsWith("localhost")) {
    return Environment.Local;
  }

  return Environment.Unknown;
}

export function getEnvironmentPrefix(returnQaIfLocal = false) {
  const env = getEnvironment();
  if (env === Environment.Prod) {
    return "prod";
  }
  if (
    env === Environment.QA ||
    (returnQaIfLocal && env === Environment.Local)
  ) {
    return "qa";
  }
  if (env === Environment.Local) {
    return "local";
  }

  return "unknown";
}

export function getApiUrl() {
  const env = getEnvironment();

  if (env === Environment.Local) {
    switch (TARGET_ENVIRONMENT_IF_LOCAL) {
      case RemoteEnvironment.Localhost:
        return "http://localhost:7000/public";
      case RemoteEnvironment.QA:
        return `https://apiqa.campfirehosting.com/public`;
      case RemoteEnvironment.Prod:
        return `https://api.campfirehosting.com/public`;
    }
  }

  let domain = "api";
  if (env === Environment.QA) {
    domain += "qa";
  }

  return `https://${domain}.campfirehosting.com/public`;
}
