import { useAuth0 } from "@auth0/auth0-react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import makeAsyncComponent from "./utils/makeAsyncComponent";

import ProtectedRoute from "./ProtectedRoute";
import { security } from "./redux/security";
import {
  getAccountUrl,
  getCreateServerUrl,
  getCreditsUrl,
  getDeleteServerUrl,
  getServerAdvancedPropertiesUrl,
  getServerFtpUrl,
  getServerGeneralUrl,
  getServerMonitoringUrl,
  getServerPropertiesUrl,
  getServersOverviewUrl,
  getSupportUrl,
} from "./utils/urls";

const AsyncHome = makeAsyncComponent(() => import("./modules/home/HomePage"));

const AsyncAdminLayout = makeAsyncComponent(
  () => import("./modules/admin/components/container/AdminLayout")
);
const AsyncServersOverviewPage = makeAsyncComponent(
  () => import("./modules/admin/server/ServersOverviewPage")
);
const AsyncServerDetailsPage = makeAsyncComponent(
  () => import("./modules/admin/server/ServerDetailsPage")
);
const AsyncCreditsPage = makeAsyncComponent(
  () => import("./modules/admin/credits/CreditsPage")
);

const AsyncAccountPage = makeAsyncComponent(
  () => import("./modules/admin/account/AccountPage")
);
const AsyncSupportPage = makeAsyncComponent(
  () => import("./modules/admin/account/SupportPage")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/" element={<AsyncHome />} />
      <Route element={<ProtectedRoute component={AsyncAdminLayout} />}>
        <Route
          path={getServersOverviewUrl()}
          element={<AsyncServersOverviewPage />}
        />

        <Route
          path={getCreateServerUrl()}
          element={<AsyncServerDetailsPage />}
        />

        <Route
          path={getDeleteServerUrl(":serverId")}
          element={<AsyncServerDetailsPage />}
        />
        <Route
          path={getServerGeneralUrl(":serverId")}
          element={<AsyncServerDetailsPage />}
        />
        <Route
          path={getServerMonitoringUrl(":serverId")}
          element={<AsyncServerDetailsPage />}
        />
        <Route
          path={getServerAdvancedPropertiesUrl(":serverId")}
          element={<AsyncServerDetailsPage />}
        />
        <Route
          path={getServerFtpUrl(":serverId")}
          element={<AsyncServerDetailsPage />}
        />
        <Route
          path={getServerPropertiesUrl(":serverId")}
          element={<AsyncServerDetailsPage />}
        />

        <Route path={getCreditsUrl()} element={<AsyncCreditsPage />} />
        <Route path={getAccountUrl()} element={<AsyncAccountPage />} />
        <Route path={getSupportUrl()} element={<AsyncSupportPage />} />
      </Route>
    </Route>
  )
);

export default function Routes() {
  const { getAccessTokenSilently } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);

  return <RouterProvider router={router} />;
}
