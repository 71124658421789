import { createApi } from "@reduxjs/toolkit/query/react";
import { CreateServerRequest } from "./models/request/CreateServerRequest";
import { GetServerEventsRequest } from "./models/request/GetServerEventsRequest";
import { GetServerMetricsRequest } from "./models/request/GetServerMetricsRequest";
import { InitCheckoutSessionRequest } from "./models/request/InitCheckoutSessionRequest";
import { SendCommandRequest } from "./models/request/SendCommandRequest";
import { TimeRangeRequest } from "./models/request/TimeRangeRequest";
import { UpdateServerPropertiesRequest } from "./models/request/UpdateServerPropertiesRequest";
import { UpdateServerRequest } from "./models/request/UpdateServerRequest";
import { GetBillingHistoryResponse } from "./models/response/GetBillingHistoryResponse";
import { GetLogContentResponse } from "./models/response/GetLogContentsResponse";
import { GetLogsResponse } from "./models/response/GetLogsResponse";
import { GetMetricsResponse } from "./models/response/GetMetricsResponse";
import { GetOnlinePlayersResponse } from "./models/response/GetOnlinePlayersResponse";
import { GetServerCommandResponse } from "./models/response/GetServerCommandResponse";
import { GetServerDetailsResponse } from "./models/response/GetServerDetailsResponse";
import { GetServerEventsResponse } from "./models/response/GetServerEventsResponse";
import { GetServerInfoResponse } from "./models/response/GetServerInfoResponse";
import { GetServersResponse } from "./models/response/GetServerOverviewResponse";
import { GetServerPropertiesResponse } from "./models/response/GetServerPropertiesResponse";
import { GetUserResponse } from "./models/response/GetUserResponse";
import { GetVersionResponse } from "./models/response/GetVersionsResponse";
import { InitCheckoutSessionResponse } from "./models/response/InitCheckoutSessionResponse";
import { MutateServerResponse } from "./models/response/MutateServerResponse";
import { PostSearchCreditsUsageResponse } from "./models/response/PostSearchCreditsUsageResponse";
import { ValidateSubdomainResponse } from "./models/response/ValidateSubdomainResponse";

import { getGaCookies } from "../utils/cookieUtils";
import { removeKeyFromObject } from "../utils/objectUtils";
import {
  externalBaseQueryWithRetry,
  internalBaseQueryWithRetry,
} from "./baseQueriesWithRetry";
import { AddFtpUserInput } from "./models/request/AddFtpUserInput";
import { CreateEventBase } from "./models/request/CreateEventRequest";
import { DeleteFtpUserInput } from "./models/request/DeleteFtpUserInput";
import { GetSftpUsersResponse } from "./models/response/GetSftpUsersResponse";
import {
  GetFabricVersionsResponse,
  GetForgeVersionsResponse,
  GetJavaVersionsResponse,
  GetNeoForgeVersionsResponse,
  GetPaperVersionsResponse,
} from "./models/response/GetVersionsResponses";
import { UpdateUserRequest } from "./models/response/UpdateUserRequest";

export const internalApi = createApi({
  reducerPath: "api",
  baseQuery: internalBaseQueryWithRetry,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    // Server
    getServersOverview: builder.query<GetServersResponse, void>({
      query: () => ({
        url: "server-management/servers",
        method: "GET",
      }),
    }),
    getServerDetails: builder.query<GetServerDetailsResponse, string>({
      query: (serverId: string) => ({
        url: `server-management/server/${serverId}`,
        method: "GET",
      }),
    }),
    createServer: builder.mutation<MutateServerResponse, CreateServerRequest>({
      query: (input) => ({
        url: `server-management/server`,
        method: "POST",
        body: input,
      }),
    }),
    updateServer: builder.mutation<
      MutateServerResponse,
      UpdateServerRequest & { serverId: string }
    >({
      query: (input) => ({
        url: `server-management/server/${input.serverId}`,
        method: "PATCH",
        body: removeKeyFromObject("serverId", input),
      }),
    }),
    deleteServer: builder.mutation<void, string>({
      query: (serverId: string) => ({
        url: `/server-management/server/${serverId}`,
        method: "DELETE",
      }),
    }),

    stopServer: builder.mutation<void, string>({
      query: (serverId: string) => ({
        url: `/server-management/server/${serverId}/stop`,
        method: "POST",
      }),
    }),
    startServer: builder.mutation<void, string>({
      query: (serverId: string) => ({
        url: `/server-management/server/${serverId}/start`,
        method: "POST",
      }),
    }),
    restoreFromColdStorage: builder.mutation<void, string>({
      query: (serverId: string) => ({
        url: `/server-management/server/${serverId}/move-from-cold-storage`,
        method: "POST",
      }),
    }),

    pauseServer: builder.mutation<void, string>({
      query: (serverId: string) => ({
        url: `/server-management/server/${serverId}/pause`,
        method: "POST",
      }),
    }),

    unpauseServer: builder.mutation<void, string>({
      query: (serverId: string) => ({
        url: `/server-management/server/${serverId}/unpause`,
        method: "POST",
      }),
    }),

    validateSubdomain: builder.query<ValidateSubdomainResponse, string>({
      query: (subdomain: string) => ({
        url: `server-management/subdomain/${subdomain}/validate`,
        method: "GET",
      }),
    }),

    // Versions
    getJavaVersions: builder.query<GetJavaVersionsResponse, void>({
      query: () => ({
        url: `versions/java`,
        method: "GET",
      }),
    }),
    getFabricVersions: builder.query<GetFabricVersionsResponse, void>({
      query: () => ({
        url: `versions/fabric`,
        method: "GET",
      }),
    }),
    getForgeVersions: builder.query<GetForgeVersionsResponse, void>({
      query: () => ({
        url: `versions/forge`,
        method: "GET",
      }),
    }),
    getPaperVersions: builder.query<GetPaperVersionsResponse, void>({
      query: () => ({
        url: `versions/paper`,
        method: "GET",
      }),
    }),
    getNeoForgeVersions: builder.query<GetNeoForgeVersionsResponse, void>({
      query: () => ({
        url: `versions/neoforge`,
        method: "GET",
      }),
    }),

    // Server - File Manager
    getServerFtpUsers: builder.query<GetSftpUsersResponse, string>({
      query: (serverId) => ({
        url: `server-management/server/${serverId}/sftp/users`,
        method: "GET",
      }),
    }),
    addFtpUser: builder.mutation<void, AddFtpUserInput & { serverId: string }>({
      query: (input) => ({
        url: `server-management/server/${input.serverId}/sftp/users`,
        method: "POST",
        body: input,
      }),
    }),
    deleteFtpUser: builder.mutation<
      void,
      DeleteFtpUserInput & { serverId: string }
    >({
      query: (input) => ({
        url: `/server-management/server/${input.serverId}/sftp/user/${input.username}`,
        method: "DELETE",
      }),
    }),
    // Server - properties
    getServerProperties: builder.query<GetServerPropertiesResponse, string>({
      query: (serverId: string) => ({
        url: `server-management/server/${serverId}/properties`,
        method: "GET",
      }),
    }),
    updateServerProperties: builder.mutation<
      void,
      UpdateServerPropertiesRequest & { serverId: string }
    >({
      query: (input) => ({
        url: `server-management/server/${input.serverId}/properties`,
        method: "PUT",
        body: input,
      }),
    }),

    // Server - monitoring
    getServerMetrics: builder.query<
      GetMetricsResponse,
      GetServerMetricsRequest
    >({
      query: ({ serverId, timeRange }) => ({
        url: `server-logs/server/${serverId}/resource-metrics/search/`,
        method: "POST",
        body: timeRange,
      }),
    }),
    getServerEvents: builder.query<
      GetServerEventsResponse,
      GetServerEventsRequest
    >({
      query: (input) => ({
        url: `server-logs/server/${input.serverId}/events/`,
        method: "GET",
        params: input,
      }),
    }),

    // Server - logs
    getOnlinePlayersMetrics: builder.query<
      GetOnlinePlayersResponse,
      GetServerMetricsRequest
    >({
      query: ({ serverId, timeRange }) => ({
        url: `server-logs/server/${serverId}/online-players/search`,
        method: "POST",
        body: timeRange,
      }),
    }),
    getCreditsUsage: builder.query<
      PostSearchCreditsUsageResponse,
      TimeRangeRequest
    >({
      query: (body) => ({
        url: "server-logs/credits-usage/search",
        method: "POST",
        body,
      }),
    }),
    getLogs: builder.query<GetLogsResponse, string>({
      query: (serverId: string) => ({
        url: `server-logs/server/${serverId}/logs`,
        method: "GET",
      }),
    }),
    getBillingHistory: builder.query<GetBillingHistoryResponse, void>({
      query: () => ({
        url: `users/user/billing`,
        method: "GET",
      }),
    }),
    sendServerCommand: builder.mutation<
      GetServerCommandResponse,
      SendCommandRequest
    >({
      query: (input) => ({
        url: `server-management/server/${input.serverId}/send-command`,
        method: "POST",
        body: {
          command: input.command,
        },
      }),
    }),
    getLogContents: builder.query<
      GetLogContentResponse,
      { serverId: string; fileName: string }
    >({
      query: ({ serverId, fileName }) => ({
        url: `server-logs/server/${serverId}/logs/${fileName}`,
        method: "GET",
      }),
    }),

    // Server - Account
    initCheckoutSession: builder.query<
      InitCheckoutSessionResponse,
      InitCheckoutSessionRequest
    >({
      query: (input) => ({
        url: "payments/checkout",
        method: "POST",
        body: input,
      }),
    }),

    // User
    getUser: builder.query<GetUserResponse, void>({
      query: () => ({
        url: "users/user",
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<GetUserResponse, UpdateUserRequest>({
      query: (input) => ({
        url: "users/user",
        method: "PATCH",
        body: {
          playBrowserSounds: input.playBrowserSounds,
        },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const externalApi = createApi({
  reducerPath: "externalApi",
  baseQuery: externalBaseQueryWithRetry,
  endpoints: (builder) => ({
    getServerInfo: builder.query<GetServerInfoResponse, void>({
      query: () => ({ url: "server-info", method: "GET" }),
      transformResponse: (response: GetServerInfoResponse) => {
        response.pricingPackages.map((pricingPackage) => {
          pricingPackage.cpu /= 1024;
          pricingPackage.ram /= 1024;
          return pricingPackage;
        });

        return response;
      },
    }),
    getVersions: builder.query<GetVersionResponse, void>({
      query: () => ({ url: "server-info/versions", method: "GET" }),
    }),
    createEvent: builder.mutation<void, CreateEventBase>({
      query: (input) => ({
        url: "a/e",
        method: "POST",
        body: {
          gaCookies: getGaCookies(),
          eventName: input.eventName,
          parameters: input.parameters || {},
        },
      }),
    }),
  }),
});

export const {
  useUpdateServerPropertiesMutation,
  useGetServerMetricsQuery,
  useLazyGetServerMetricsQuery,
  useGetServersOverviewQuery,
  useGetServerDetailsQuery,
  useGetServerFtpUsersQuery,
  useLazyGetServerFtpUsersQuery,
  useAddFtpUserMutation,
  useDeleteFtpUserMutation,
  useGetServerPropertiesQuery,
  useLazyGetServerEventsQuery,
  useCreateServerMutation,
  useLazyGetServerDetailsQuery,
  useRestoreFromColdStorageMutation,
  useGetUserQuery,
  useStopServerMutation,
  useStartServerMutation,
  useDeleteServerMutation,
  useGetCreditsUsageQuery,
  useLazyGetCreditsUsageQuery,
  useGetOnlinePlayersMetricsQuery,
  useSendServerCommandMutation,
  useGetLogsQuery,
  usePauseServerMutation,
  useUnpauseServerMutation,
  useGetBillingHistoryQuery,
  useLazyGetBillingHistoryQuery,
  useLazyGetLogsQuery,
  useLazyGetLogContentsQuery,
  useUpdateServerMutation,
  useLazyValidateSubdomainQuery,
  useLazyGetOnlinePlayersMetricsQuery,
  useLazyInitCheckoutSessionQuery,
  useGetJavaVersionsQuery,
  useGetFabricVersionsQuery,
  useGetForgeVersionsQuery,
  useGetPaperVersionsQuery,
  useLazyGetJavaVersionsQuery,
  useLazyGetFabricVersionsQuery,
  useLazyGetForgeVersionsQuery,
  useLazyGetPaperVersionsQuery,
  useLazyGetNeoForgeVersionsQuery,
  useUpdateUserMutation,
} = internalApi;

export const { useGetServerInfoQuery, useCreateEventMutation } = externalApi;
