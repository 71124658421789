import { Component } from "react";
import LoadingHoC from "../components/progress/LoadingHoC";

export default function makeAsyncComponent(importComponent: any) {
  class AsyncComponent extends Component<
    any,
    { component: any; isLoading: boolean }
  > {
    constructor(props: any) {
      super(props);

      this.state = {
        component: null,
        isLoading: true,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component,
        isLoading: false,
      });
    }

    render() {
      const C = this.state.component;

      return (
        <LoadingHoC
          center={true}
          height="300px"
          isLoading={this.state.isLoading}
        >
          {C ? <C {...this.props} /> : null}
        </LoadingHoC>
      );
    }
  }

  return AsyncComponent;
}
