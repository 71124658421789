import { defineStyleConfig } from "@chakra-ui/react";

const MenuButton = defineStyleConfig({
  baseStyle: {
    _expanded: {
      bg: "blue.900",
    },
  },
});

export default MenuButton;
