import { CircularProgress } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { ConstHeightDiv } from "../styled/StyledDiv";

type Props = PropsWithChildren<{
  isLoading: boolean;
  height?: string;
  className?: string;
  center?: boolean;
}>;

export default function LoadingHoC(props: Props) {
  let { className } = props;
  className = props.center
    ? `${className || ""} u-width-100 u-center`
    : className;

  if (props.isLoading) {
    const Progress = <CircularProgress isIndeterminate={true} />;
    return props.height ? (
      <ConstHeightDiv className={className} height={props.height}>
        {Progress}
      </ConstHeightDiv>
    ) : (
      <div className={className}>{Progress}</div>
    );
  }

  return <>{props.children}</>;
}
