import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { security } from "./security";
import { getApiUrl } from "../utils/environment";

const RETRY_DELAY_MS = 3000;
const API_BASE_URL = getApiUrl();

const backoff = async (__: number, ___: number) => {
  await new Promise((resolve) => {
    setTimeout(resolve, RETRY_DELAY_MS);
  });
};

export const internalBaseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: async (headers) => {
    const access_token = await security.getAccessTokenSilently()();
    if (access_token) {
      headers.set("Authorization", `Bearer ${access_token}`);
    }
    return headers;
  },
});

export const externalBaseQuery = fetchBaseQuery({ baseUrl: API_BASE_URL });

export const internalBaseQueryWithRetry = retry(internalBaseQuery, {
  backoff,
});

export const externalBaseQueryWithRetry = retry(externalBaseQuery, {
  backoff,
});
