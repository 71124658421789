import { defineStyleConfig } from "@chakra-ui/react";

const disabledStyles = {
  backgroundColor: "blue.400",
  borderColor: "blue.400",
  color: "whiteAlpha.900",
  bg: "blue.400",
};

const Button = defineStyleConfig({
  variants: {
    _disabled: {
      ...disabledStyles,
      pointerEvents: "none",
    },
    solid: {
      bg: "blue.600",
      color: "whiteAlpha.900",
      _disabled: disabledStyles,
      _hover: {
        bg: "blue.500",
        _disabled: disabledStyles,
      },
    },
    link: {
      fontWeight: "bold",
      color: "blue.600",
      textColor: "blue.600",
      _disabled: disabledStyles,
      _hover: {
        color: "blue.600",
        textColor: "blue.600",
      },
    },
    // The default size and variant values
    defaultProps: {
      variant: "solid",
    },
  },
});

export default Button;
