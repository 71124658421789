export function getGaCookies() {
  return Object.entries(getAllCookies()).reduce((acc, [key, value]) => {
    if (key.startsWith("_ga")) {
      acc[key] = value;
    }

    return acc;
  }, {} as Record<string, string>);
}

function getAllCookies() {
  return document.cookie
    .split(";")
    .reduce(
      (ac, str) =>
        Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
      {} as Record<string, string>
    );
}
