import { withAuthenticationRequired } from "@auth0/auth0-react";

type Props = {
  component: React.ComponentType;
};

export default function ProtectedRoute({ component }: Props) {
  const Component = withAuthenticationRequired(component);
  return <Component />;
}
